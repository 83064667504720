<template>
  <b-form-group
    class="control-files"
    :label="control.label"
    :label-for="control.id"
    :label-sr-only="control.labelSrOnly || control.labelHide"
    :label-cols="control.labelCols"
    :label-cols-sm="control.labelColsSM"
    :label-cols-md="control.labelColsMD"
    :label-cols-lg="control.labelColsLG"
    :label-cols-xl="control.labelColsXL"
    :label-align="control.labelAlign"
    :label-align-sm="control.labelAlignSM"
    :label-align-md="control.labelAlignMD"
    :label-align-lg="control.labelAlignLG"
    :label-align-xl="control.labelAlignXL"
    :class="formGroupClasses"
    :description="control.description"
    :state="controlState"
  >
    <file-pond
      ref="filepond"
      :server="controlServer"
      :class="controlClasses"
      :credits="''"

      :id="control.id"
      :name="control.name"
      :form="control.form"
      :files="control.files"
      :disabled="control.disabled || control.readonly"

      :allowDrop="control.allowDrop"
      :allowBrowse="control.allowBrowse"
      :allowPaste="control.allowPaste"
      allowMultiple
      :allowReplace="control.allowReplace"
      :allowRevert="control.allowRevert"
      :allowRemove="control.allowRemove"
      :allowProcess="control.allowProcess"
      :allowReorder="control.allowReorder"
      :forceRevert="control.forceRevert"
      :maxFiles="control.maxFiles"
      :checkValidity="control.checkValidity"
      :itemInsertLocation="control.itemInsertLocation"
      :itemInsertInterval="control.itemInsertInterval"
      :dropOnPage="control.dropOnPage"
      :dropOnElement="control.dropOnElement"
      :dropValidation="control.dropValidation"
      :ignoredFiles="control.ignoredFiles"
      :instantUpload="control.instantUpload"
      :labelDecimalSeparator="control.labelDecimalSeparator"
      :labelThousandsSeparator="control.labelThousandsSeparator"
      :labelIdle="control.labelIdle"
      :labelInvalidField="control.labelInvalidField"
      :labelFileWaitingForSize="control.labelFileWaitingForSize"
      :labelFileSizeNotAvailable="control.labelFileSizeNotAvailable"
      :labelFileLoading="control.labelFileLoading"
      :labelFileLoadError="control.labelFileLoadError"
      :labelFileProcessing="control.labelFileProcessing"
      :labelFileProcessingComplete="control.labelFileProcessingComplete"
      :labelFileProcessingAborted="control.labelFileProcessingAborted"
      :labelFileProcessingError="control.labelFileProcessingError"
      :labelFileProcessingRevertError="control.labelFileProcessingRevertError"
      :labelFileRemoveError="control.labelFileRemoveError"
      :labelTapToCancel="control.labelTapToCancel"
      :labelTapToRetry="control.labelTapToRetry"
      :labelTapToUndo="control.labelTapToUndo"
      :labelButtonRemoveItem="control.labelButtonRemoveItem"
      :labelButtonAbortItemLoad="control.labelButtonAbortItemLoad"
      :labelButtonRetryItemLoad="control.labelButtonRetryItemLoad"
      :labelButtonAbortItemProcessing="control.labelButtonAbortItemProcessing"
      :labelButtonUndoItemProcessing="control.labelButtonUndoItemProcessing"
      :labelButtonRetryItemProcessing="control.labelButtonRetryItemProcessing"
      :labelButtonProcessItem="control.labelButtonProcessItem"

      :allowFileTypeValidation="control.allowFileTypeValidation"
      :acceptedFileTypes="control.acceptedFileTypes"
      :labelFileTypeNotAllowed="control.labelFileTypeNotAllowed"
      :fileValidateTypeLabelExpectedTypes="control.fileValidateTypeLabelExpectedTypes"
      :fileValidateTypeLabelExpectedTypesMap="control.fileValidateTypeLabelExpectedTypesMap"
      :fileValidateTypeDetectType="control.fileValidateTypeDetectType"

      :allowFileSizeValidation="control.allowFileSizeValidation"
      :maxFileSize="control.maxFileSize"
      :maxTotalFileSize="control.maxTotalFileSize"
      :labelMaxFileSizeExceeded="control.labelMaxFileSizeExceeded"
      :labelMaxFileSize="control.labelMaxFileSize"
      :labelMaxTotalFileSizeExceeded="control.labelMaxTotalFileSizeExceeded"
      :labelMaxTotalFileSize="control.labelMaxTotalFileSize"

      @processfile="onProcessFile"
      @removefile="onRemoveFile"
      @click.native="onClick"
    />

    <b-form-valid-feedback v-if="control.feedbacksValid" :state="controlState">{{ control.feedbacksValid }}</b-form-valid-feedback>
    <b-form-invalid-feedback :state="controlState">{{ controlInvalidFeedbacks }}</b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { FILE_ENDPOINT_DEFAULT_URLS } from '@/constants'

import VueFilePond from 'vue-filepond'
import { FileOrigin } from 'filepond'
import { copy } from '@/assets/js/helper/object'

import FilePondPluginGetFile from 'filepond-plugin-get-file'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

export default {
  name: 'ControlFiles',
  components: {
    FilePond: VueFilePond(
      FilePondPluginGetFile,
      FilePondPluginFileValidateType,
      FilePondPluginFileValidateSize
    )
  },
  props: {
    // Base Control
    id: { type: String, default: '' },
    name: { type: String, default: '' },
    form: { type: String, default: '' },
    formGroupClass: { type: [String, Object, Array], default: '' },
    controlClass: { type: [String, Object, Array], default: '' },
    label: { type: String, default: '' },
    labelSrOnly: { type: [Boolean], default: false },
    labelHide: { type: Boolean, default: false },
    labelCols: { type: [Number, String, Boolean], default: null },
    labelColsSM: { type: [Number, String, Boolean], default: null },
    labelColsMD: { type: [Number, String, Boolean], default: null },
    labelColsLG: { type: [Number, String, Boolean], default: null },
    labelColsXL: { type: [Number, String, Boolean], default: null },
    labelAlign: { type: String, default: '' },
    labelAlignSM: { type: String, default: '' },
    labelAlignMD: { type: String, default: '' },
    labelAlignLG: { type: String, default: '' },
    labelAlignXL: { type: String, default: '' },
    value: { type: Array, default: () => ([]) },
    description: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    dirty: { type: Boolean, default: false },
    validations: { type: Object, default: () => ({}) },
    feedbacksValid: { type: String, default: '' },
    feedbacksInvalid: { type: Object, default: () => ({}) },
    hardChange: { type: Function, default: null },
    // FilePond
    serverConfig: { type: Object, default: () => ({}) },
    allowDrop: { type: Boolean, default: true },
    allowBrowse: { type: Boolean, default: true },
    allowPaste: { type: Boolean, default: true },
    // allowMultiple: { type: Boolean, default: true },
    allowReplace: { type: Boolean, default: true },
    allowRevert: { type: Boolean, default: true },
    allowRemove: { type: Boolean, default: true },
    allowProcess: { type: Boolean, default: true },
    allowReorder: { type: Boolean, default: false },
    forceRevert: { type: Boolean, default: false },
    maxFiles: { type: Number, default: null },
    checkValidity: { type: Boolean, default: false },
    itemInsertLocation: { type: String, default: 'before' },
    itemInsertInterval: { type: Number, default: 75 },
    dropOnPage: { type: Boolean, default: false },
    dropOnElement: { type: Boolean, default: true },
    dropValidation: { type: Boolean, default: false },
    ignoredFiles: { type: Array, default: () => (['.ds_store', 'thumbs.db', 'desktop.ini']) },
    instantUpload: { type: Boolean, default: true },
    labelDecimalSeparator: { type: String, default: 'auto' },
    labelThousandsSeparator: { type: String, default: 'auto' },
    labelIdle: { type: String, default: null },
    labelInvalidField: { type: String, default: null },
    labelFileWaitingForSize: { type: String, default: null },
    labelFileSizeNotAvailable: { type: String, default: null },
    labelFileLoading: { type: String, default: null },
    labelFileLoadError: { type: String, default: null },
    labelFileProcessing: { type: String, default: null },
    labelFileProcessingComplete: { type: String, default: null },
    labelFileProcessingAborted: { type: String, default: null },
    labelFileProcessingError: { type: String, default: null },
    labelFileProcessingRevertError: { type: String, default: null },
    labelFileRemoveError: { type: String, default: null },
    labelTapToCancel: { type: String, default: null },
    labelTapToRetry: { type: String, default: null },
    labelTapToUndo: { type: String, default: null },
    labelButtonRemoveItem: { type: String, default: null },
    labelButtonAbortItemLoad: { type: String, default: null },
    labelButtonRetryItemLoad: { type: String, default: null },
    labelButtonAbortItemProcessing: { type: String, default: null },
    labelButtonUndoItemProcessing: { type: String, default: null },
    labelButtonRetryItemProcessing: { type: String, default: null },
    labelButtonProcessItem: { type: String, default: null },
    // Plugin Type Validation
    allowFileTypeValidation: { type: Boolean, default: true },
    acceptedFileTypes: {
      type: Array,
      // Sources: https://www.iana.org/assignments/media-types/media-types.xhtml | https://developer.mozilla.org/ar/docs/Web/HTTP/Basics_of_HTTP/MIME_types
      // Wildcards are possible. e.g. ['image/*']
      default: () => ([
        'image/webp',
        'image/gif',
        'image/png',
        'image/jpeg',
        'image/svg+xml',

        'video/webm',
        'video/ogg',
        'video/mp4',

        'audio/weba',
        'audio/ogg',
        'audio/mp4',
        'audio/mpeg',
        'audio/wav',
        'audio/midi',

        'application/ogg',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      ])
    },
    labelFileTypeNotAllowed: { type: String, default: null },
    fileValidateTypeLabelExpectedTypes: { type: String, default: null },
    fileValidateTypeLabelExpectedTypesMap: {
      type: Object,
      default: () => ({
        'image/webp': 'webp',
        'image/gif': 'gif',
        'image/png': 'png',
        'image/jpeg': 'jpg',
        'image/svg+xml': 'svg',

        'video/webm': 'webm',
        'video/ogg': 'ogg',
        'video/mp4': 'mp4',

        'audio/weba': 'weba',
        'audio/ogg': null,
        'audio/mp4': null,
        'audio/mpeg': 'mpeg',
        'audio/wav': 'wav',
        'audio/midi': 'midi',

        'application/ogg': null,
        'application/pdf': 'pdf',
        'application/msword': 'doc',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
        'application/vnd.ms-excel': 'xls',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
        'application/vnd.ms-powerpoint': 'ppt',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx'
      })
    },
    fileValidateTypeDetectType: { type: Function, default: null },
    // Plugin Size Validation
    allowFileSizeValidation: { type: Boolean, default: true },
    maxFileSize: { type: Number, default: null }, // e.g. 123456 (bytes)
    maxTotalFileSize: { type: Number, default: null }, // e.g. 12345678765 (bytes)
    labelMaxFileSizeExceeded: { type: String, default: null },
    labelMaxFileSize: { type: String, default: null },
    labelMaxTotalFileSizeExceeded: { type: String, default: null },
    labelMaxTotalFileSize: { type: String, default: null }
  },
  data () {
    return {
      // https://pqina.nl/filepond/docs/patterns/api/server/
      controlServer: {
        url: this.$http.defaults.baseURL,
        process: { // Upload Files (POST)
          url: this.$props.serverConfig.uploadUrl || FILE_ENDPOINT_DEFAULT_URLS.upload
        },
        patch: null, // Upload chunked Files (PATCH)
        restore: { // Load temporary uploaded File (GET)
          url: this.$props.serverConfig.downloadUrl || FILE_ENDPOINT_DEFAULT_URLS.delete
        },
        load: { // Load uploaded File (GET)
          url: this.$props.serverConfig.downloadUrl || FILE_ENDPOINT_DEFAULT_URLS.delete
        },
        fetch: null, // Load Files from remote Server (GET)
        revert: { // Delete temporary uploaded File (DELETE)
          url: this.$props.serverConfig.uploadUrl || FILE_ENDPOINT_DEFAULT_URLS.upload
        },
        // Delete uploaded File (DELETE)
        // this can only be null or a callback function, e.g.
        // visit https://pqina.nl/filepond/docs/patterns/api/server/#remove-1 for further informations
        remove: this.removeFileFromServer,
        removeUrl: this.$props.serverConfig.deleteUrl || FILE_ENDPOINT_DEFAULT_URLS.delete
      },
      control: {
        // Swagger-Definition: http://pim.ebi-pharm.ch/swagger/#operations-tag-Produkte
        id: this.$props.id,
        name: this.$props.name,
        form: this.$props.form,
        formGroupClass: this.$props.formGroupClass,
        controlClass: this.$props.controlClass,
        label: this.$props.label,
        files: this.createInitialFiles(),
        value: this.$props.value,
        labelSrOnly: this.$props.labelSrOnly,
        labelHide: this.$props.labelHide,
        labelCols: this.$props.labelCols,
        labelColsSM: this.$props.labelColsSM,
        labelColsMD: this.$props.labelColsMD,
        labelColsLG: this.$props.labelColsLG,
        labelColsXL: this.$props.labelColsXL,
        labelAlign: this.$props.labelAlign,
        labelAlignSM: this.$props.labelAlignSM,
        labelAlignMD: this.$props.labelAlignMD,
        labelAlignLG: this.$props.labelAlignLG,
        labelAlignXL: this.$props.labelAlignXL,
        description: this.$props.description,
        readonly: this.$props.readonly,
        disabled: this.$props.disabled,
        dirty: this.$props.dirty,
        validations: this.$props.validations,
        feedbacksValid: this.$props.feedbacksValid,
        feedbacksInvalid: this.$props.feedbacksInvalid,
        // FilePond
        allowDrop: this.$props.allowDrop,
        allowBrowse: this.$props.allowBrowse,
        allowPaste: this.$props.allowPaste,
        // allowMultiple: this.$props.allowMultiple,
        allowReplace: this.$props.allowReplace,
        allowRevert: this.$props.allowRevert,
        allowRemove: this.$props.allowRemove,
        allowProcess: this.$props.allowProcess,
        allowReorder: this.$props.allowReorder,
        forceRevert: this.$props.forceRevert,
        maxFiles: this.$props.maxFiles,
        checkValidity: this.$props.checkValidity,
        itemInsertLocation: this.$props.itemInsertLocation,
        itemInsertInterval: this.$props.itemInsertInterval,
        dropOnPage: this.$props.dropOnPage,
        dropOnElement: this.$props.dropOnElement,
        dropValidation: this.$props.dropValidation,
        ignoredFiles: this.$props.ignoredFiles,
        instantUpload: this.$props.instantUpload,
        labelDecimalSeparator: this.$props.labelDecimalSeparator,
        labelThousandsSeparator: this.$props.labelThousandsSeparator,
        labelIdle: this.$props.labelIdle || this.$t('components.controls.file.label.idle', { br: '<br/>', browse: `<span class="filepond--label-action">${this.$t('components.controls.file.label.browse')}</span>` }),
        labelInvalidField: this.$props.labelInvalidField || this.$t('components.controls.file.label.InvalidField'),
        labelFileWaitingForSize: this.$props.labelFileWaitingForSize || this.$t('components.controls.file.label.FileWaitingForSize'),
        labelFileSizeNotAvailable: this.$props.labelFileSizeNotAvailable || this.$t('components.controls.file.label.FileSizeNotAvailable'),
        labelFileLoading: this.$props.labelFileLoading || this.$t('components.controls.file.label.FileLoading'),
        labelFileLoadError: this.$props.labelFileLoadError || this.$t('components.controls.file.label.FileLoadError'),
        labelFileProcessing: this.$props.labelFileProcessing || this.$t('components.controls.file.label.FileProcessing'),
        labelFileProcessingComplete: this.$props.labelFileProcessingComplete || this.$t('components.controls.file.label.FileProcessingComplete'),
        labelFileProcessingAborted: this.$props.labelFileProcessingAborted || this.$t('components.controls.file.label.FileProcessingAborted'),
        labelFileProcessingError: this.$props.labelFileProcessingError || this.$t('components.controls.file.label.FileProcessingError'),
        labelFileProcessingRevertError: this.$props.labelFileProcessingRevertError || this.$t('components.controls.file.label.FileProcessingRevertError'),
        labelFileRemoveError: this.$props.labelFileRemoveError || this.$t('components.controls.file.label.FileRemoveError'),
        labelTapToCancel: this.$props.labelTapToCancel || this.$t('components.controls.file.label.TapToCancel'),
        labelTapToRetry: this.$props.labelTapToRetry || this.$t('components.controls.file.label.TapToRetry'),
        labelTapToUndo: this.$props.labelTapToUndo || this.$t('components.controls.file.label.TapToUndo'),
        labelButtonRemoveItem: this.$props.labelButtonRemoveItem || this.$t('components.controls.file.label.ButtonRemoveItem'),
        labelButtonAbortItemLoad: this.$props.labelButtonAbortItemLoad || this.$t('components.controls.file.label.ButtonAbortItemLoad'),
        labelButtonRetryItemLoad: this.$props.labelButtonRetryItemLoad || this.$t('components.controls.file.label.ButtonRetryItemLoad'),
        labelButtonAbortItemProcessing: this.$props.labelButtonAbortItemProcessing || this.$t('components.controls.file.label.ButtonAbortItemProcessing'),
        labelButtonUndoItemProcessing: this.$props.labelButtonUndoItemProcessing || this.$t('components.controls.file.label.ButtonUndoItemProcessing'),
        labelButtonRetryItemProcessing: this.$props.labelButtonRetryItemProcessing || this.$t('components.controls.file.label.ButtonRetryItemProcessing'),
        labelButtonProcessItem: this.$props.labelButtonProcessItem || this.$t('components.controls.file.label.ButtonProcessItem'),
        // Plugin Type Validation
        allowFileTypeValidation: this.$props.allowFileTypeValidation,
        acceptedFileTypes: this.$props.acceptedFileTypes,
        labelFileTypeNotAllowed: this.$props.labelFileTypeNotAllowed || this.$t('components.controls.file.label.FileTypeNotAllowed'),
        fileValidateTypeLabelExpectedTypes: this.$props.fileValidateTypeLabelExpectedTypes || this.$t('components.controls.file.label.FileExpectedTypes'),
        fileValidateTypeLabelExpectedTypesMap: this.$props.fileValidateTypeLabelExpectedTypesMap,
        fileValidateTypeDetectType: this.$props.fileValidateTypeDetectType,
        // Plugin Size Validation
        allowFileSizeValidation: this.$props.allowFileSizeValidation,
        maxFileSize: parseInt(this.$props.maxFileSize),
        maxTotalFileSize: parseInt(this.$props.maxTotalFileSize),
        labelMaxFileSizeExceeded: this.$props.labelMaxFileSizeExceeded || this.$t('components.controls.file.label.MaxFileSizeExceeded'),
        labelMaxFileSize: this.$props.labelMaxFileSize || this.$t('components.controls.file.label.MaxFileSize'),
        labelMaxTotalFileSizeExceeded: this.$props.labelMaxTotalFileSizeExceeded || this.$t('components.controls.file.label.MaxTotalFileSizeExceeded'),
        labelMaxTotalFileSize: this.$props.labelMaxTotalFileSize || this.$t('components.controls.file.label.MaxTotalFileSize')
      },
      isResetting: false
    }
  },
  validations () {
    return {
      control: {
        value: this.control.validations
      }
    }
  },
  computed: {
    validator () {
      return this.$v.control.value
    },
    controlCanValidate () {
      if (this.validator !== undefined) return this.validator.$model !== undefined ? Object.keys(this.validator.$params).length > 0 : false
      return false
    },
    controlState () {
      if (this.controlCanValidate) return this.validator.$dirty ? !this.validator.$error : null
      if (this.control.state !== null) return this.control.dirty ? this.control.state : null
      return this.control.dirty ? true : null
    },
    controlInvalidFeedbacks () {
      if (this.controlCanValidate) {
        const validations = Object.keys(this.validator.$params || {})
        const feedbacks = this.feedbacksInvalid || {}
        const error = validations.find(key => this.validator[key] === false) || ''

        return this.validator.$error ? feedbacks[error] instanceof Function ? feedbacks[error]() : feedbacks[error] || '' : ''
      }

      return ''
    },
    formGroupClasses () {
      return [
        {
          'is-disabled': this.control.disabled,
          'is-required': this.controlCanValidate,
          'is-dirty': this.controlCanValidate ? this.validator.$dirty : false
        }
      ].concat(this.control.formGroupClass || [])
    },
    controlClasses () {
      return [
        'form-control',
        {
          'is-valid': this.controlState === true,
          'is-invalid': this.controlState === false
        }
      ].concat(this.control.controlClass || [])
    }
  },
  methods: {
    createInitialFiles () {
      return []
        .concat(this.$props.value)
        .map(f => ({ source: f, options: { type: 'local' } }))
    },
    updateValueByFiles () {
      const oldValue = copy(this.control.value)
      const value = (this.$refs.filepond ? this.$refs.filepond.getFiles() : []).map(f => f.serverId)

      this.control.value = value

      if (this.hardChange) this.hardChange(this.control.value)
      if (this.control.value !== oldValue) this.onControlChange(this.control.value)
    },
    setDirty (dirty) {
      if (this.controlCanValidate) {
        if (dirty) {
          this.validator.$touch()
        } else {
          this.validator.$reset()
        }
      }
    },
    onProcessFile (error, file) {
      if (error) throw error

      this.updateValueByFiles()
    },
    onRemoveFile (error, file) {
      if (error) throw error

      if (this.isResetting) {
        this.isResetting = false
        return
      }

      this.updateValueByFiles()
    },
    onControlChange (controlValue) {
      if (this.controlCanValidate) {
        this.validator.$model = controlValue
        this.validator.$touch()
      }

      this.$emit('change', controlValue)
    },
    removeFileFromServer (source, load, error) {
      this.$http({
        method: 'delete',
        url: this.controlServer.removeUrl,
        data: source
      })
        .then(response => {
          load()
        })
        .catch(error)
    },
    onReset () {
      this.isResetting = true

      this.$refs.filepond.getFiles()
        .filter(f => f.origin !== FileOrigin.LOCAL)
        .forEach(f => {
          this.$refs.filepond.removeFile(f.id)
        })

      this.updateValueByFiles()

      this.$nextTick(() => {
        this.validator.$reset()

        this.$nextTick(() => {
          this.isResetting = false
        })
      })
    },
    onClick (event) {
      if (event.target.classList.contains('filepond--download-icon')) event.stopPropagation()
    }
  },
  created () {
    this.setDirty(this.control.dirty)
  },
  mounted () {
    this.$nextTick(() => {
      this.updateValueByFiles()
      this.$root.$emit('control:ready', Object.assign(this.control, { $touch: this.validator.$touch, $reset: this.onReset }))
    })
  },
  watch: {
    '$props.id' (id) { this.control.id = id },
    '$props.name' (name) { this.control.name = name },
    '$props.form' (form) { this.control.form = form },
    '$props.formGroupClass' (formGroupClass) { this.control.formGroupClass = formGroupClass },
    '$props.controlClass' (controlClass) { this.control.controlClass = controlClass },
    '$props.label' (label) { this.control.label = label },
    '$props.labelSrOnly' (labelSrOnly) { this.control.labelSrOnly = labelSrOnly },
    '$props.labelHide' (labelHide) { this.control.labelHide = labelHide },
    '$props.labelCols' (labelCols) { this.control.labelCols = labelCols },
    '$props.labelColsSM' (labelColsSM) { this.control.labelColsSM = labelColsSM },
    '$props.labelColsMD' (labelColsMD) { this.control.labelColsMD = labelColsMD },
    '$props.labelColsLG' (labelColsLG) { this.control.labelColsLG = labelColsLG },
    '$props.labelColsXL' (labelColsXL) { this.control.labelColsXL = labelColsXL },
    '$props.labelAlign' (labelAlign) { this.control.labelAlign = labelAlign },
    '$props.labelAlignSM' (labelAlignSM) { this.control.labelAlignSM = labelAlignSM },
    '$props.labelAlignMD' (labelAlignMD) { this.control.labelAlignMD = labelAlignMD },
    '$props.labelAlignLG' (labelAlignLG) { this.control.labelAlignLG = labelAlignLG },
    '$props.labelAlignXL' (labelAlignXL) { this.control.labelAlignXL = labelAlignXL },
    '$props.value' (value) { /* ignore changes as filePond handles its state on his own */ },
    '$props.description' (description) { this.control.description = description },
    '$props.readonly' (readonly) { this.control.readonly = readonly },
    '$props.disabled' (disabled) { this.control.disabled = disabled },
    '$props.dirty' (dirty) {
      this.control.dirty = dirty
      this.setDirty(this.control.dirty)
    },
    '$props.validations' (validations) { this.control.validations = validations },
    // '$props.validator.$dirty' ($dirty) { if (!$dirty) this.reset() },
    '$props.feedbacksValid' (feedbacksValid) { this.control.feedbacksValid = feedbacksValid },
    '$props.feedbacksInvalid' (feedbacksInvalid) { this.control.feedbacksInvalid = feedbacksInvalid },
    // FilePond
    '$props.serverConfig' (serverConfig) {
      if (serverConfig.uploadUrl) {
        this.controlServer.process.url = serverConfig.uploadUrl
        this.controlServer.revert.url = serverConfig.uploadUrl
        this.controlServer.removeUrl = serverConfig.uploadUrl
      }

      if (serverConfig.downloadUrl) {
        this.controlServer.restore.url = serverConfig.downloadUrl
        this.controlServer.load.url = serverConfig.downloadUrl
      }
    },
    '$props.allowDrop' (allowDrop) { this.control.allowDrop = allowDrop },
    '$props.allowBrowse' (allowBrowse) { this.control.allowBrowse = allowBrowse },
    '$props.allowPaste' (allowPaste) { this.control.allowPaste = allowPaste },
    // '$props.allowMultiple' (allowMultiple) { this.control.allowMultiple = allowMultiple },
    '$props.allowReplace' (allowReplace) { this.control.allowReplace = allowReplace },
    '$props.allowRevert' (allowRevert) { this.control.allowRevert = allowRevert },
    '$props.allowRemove' (allowRemove) { this.control.allowRemove = allowRemove },
    '$props.allowProcess' (allowProcess) { this.control.allowProcess = allowProcess },
    '$props.allowReorder' (allowReorder) { this.control.allowReorder = allowReorder },
    '$props.forceRevert' (forceRevert) { this.control.forceRevert = forceRevert },
    '$props.maxFiles' (maxFiles) { this.control.maxFiles = maxFiles },
    '$props.checkValidity' (checkValidity) { this.control.checkValidity = checkValidity },
    '$props.itemInsertLocation' (itemInsertLocation) { this.control.itemInsertLocation = itemInsertLocation },
    '$props.itemInsertInterval' (itemInsertInterval) { this.control.itemInsertInterval = itemInsertInterval },
    '$props.dropOnPage' (dropOnPage) { this.control.dropOnPage = dropOnPage },
    '$props.dropOnElement' (dropOnElement) { this.control.dropOnElement = dropOnElement },
    '$props.dropValidation' (dropValidation) { this.control.dropValidation = dropValidation },
    '$props.ignoredFiles' (ignoredFiles) { this.control.ignoredFiles = ignoredFiles },
    '$props.instantUpload' (instantUpload) { this.control.instantUpload = instantUpload },
    '$props.labelDecimalSeparator' (labelDecimalSeparator) { this.control.labelDecimalSeparator = labelDecimalSeparator },
    '$props.labelThousandsSeparator' (labelThousandsSeparator) { this.control.labelThousandsSeparator = labelThousandsSeparator },
    '$props.labelIdle' (labelIdle) { this.control.labelIdle = labelIdle },
    '$props.labelInvalidField' (labelInvalidField) { this.control.labelInvalidField = labelInvalidField },
    '$props.labelFileWaitingForSize' (labelFileWaitingForSize) { this.control.labelFileWaitingForSize = labelFileWaitingForSize },
    '$props.labelFileSizeNotAvailable' (labelFileSizeNotAvailable) { this.control.labelFileSizeNotAvailable = labelFileSizeNotAvailable },
    '$props.labelFileLoading' (labelFileLoading) { this.control.labelFileLoading = labelFileLoading },
    '$props.labelFileLoadError' (labelFileLoadError) { this.control.labelFileLoadError = labelFileLoadError },
    '$props.labelFileProcessing' (labelFileProcessing) { this.control.labelFileProcessing = labelFileProcessing },
    '$props.labelFileProcessingComplete' (labelFileProcessingComplete) { this.control.labelFileProcessingComplete = labelFileProcessingComplete },
    '$props.labelFileProcessingAborted' (labelFileProcessingAborted) { this.control.labelFileProcessingAborted = labelFileProcessingAborted },
    '$props.labelFileProcessingError' (labelFileProcessingError) { this.control.labelFileProcessingError = labelFileProcessingError },
    '$props.labelFileProcessingRevertError' (labelFileProcessingRevertError) { this.control.labelFileProcessingRevertError = labelFileProcessingRevertError },
    '$props.labelFileRemoveError' (labelFileRemoveError) { this.control.labelFileRemoveError = labelFileRemoveError },
    '$props.labelTapToCancel' (labelTapToCancel) { this.control.labelTapToCancel = labelTapToCancel },
    '$props.labelTapToRetry' (labelTapToRetry) { this.control.labelTapToRetry = labelTapToRetry },
    '$props.labelTapToUndo' (labelTapToUndo) { this.control.labelTapToUndo = labelTapToUndo },
    '$props.labelButtonRemoveItem' (labelButtonRemoveItem) { this.control.labelButtonRemoveItem = labelButtonRemoveItem },
    '$props.labelButtonAbortItemLoad' (labelButtonAbortItemLoad) { this.control.labelButtonAbortItemLoad = labelButtonAbortItemLoad },
    '$props.labelButtonRetryItemLoad' (labelButtonRetryItemLoad) { this.control.labelButtonRetryItemLoad = labelButtonRetryItemLoad },
    '$props.labelButtonAbortItemProcessing' (labelButtonAbortItemProcessing) { this.control.labelButtonAbortItemProcessing = labelButtonAbortItemProcessing },
    '$props.labelButtonUndoItemProcessing' (labelButtonUndoItemProcessing) { this.control.labelButtonUndoItemProcessing = labelButtonUndoItemProcessing },
    '$props.labelButtonRetryItemProcessing' (labelButtonRetryItemProcessing) { this.control.labelButtonRetryItemProcessing = labelButtonRetryItemProcessing },
    '$props.labelButtonProcessItem' (labelButtonProcessItem) { this.control.labelButtonProcessItem = labelButtonProcessItem },
    // Plugin Type Validation
    '$props.allowFileTypeValidation' (allowFileTypeValidation) { this.control.allowFileTypeValidation = allowFileTypeValidation },
    '$props.acceptedFileTypes' (acceptedFileTypes) { this.control.acceptedFileTypes = acceptedFileTypes },
    '$props.labelFileTypeNotAllowed' (labelFileTypeNotAllowed) { this.control.labelFileTypeNotAllowed = labelFileTypeNotAllowed },
    '$props.fileValidateTypeLabelExpectedTypes' (fileValidateTypeLabelExpectedTypes) { this.control.fileValidateTypeLabelExpectedTypes = fileValidateTypeLabelExpectedTypes },
    '$props.fileValidateTypeLabelExpectedTypesMap' (fileValidateTypeLabelExpectedTypesMap) { this.control.fileValidateTypeLabelExpectedTypesMap = fileValidateTypeLabelExpectedTypesMap },
    '$props.fileValidateTypeDetectType' (fileValidateTypeDetectType) { this.control.fileValidateTypeDetectType = fileValidateTypeDetectType },
    // Plugin Size Validation
    '$props.allowFileSizeValidation' (allowFileSizeValidation) { this.control.allowFileSizeValidation = allowFileSizeValidation },
    '$props.maxFileSize' (maxFileSize) { this.control.maxFileSize = parseInt(maxFileSize) },
    '$props.maxTotalFileSize' (maxTotalFileSize) { this.control.maxTotalFileSize = parseInt(maxTotalFileSize) },
    '$props.labelMaxFileSizeExceeded' (labelMaxFileSizeExceeded) { this.control.labelMaxFileSizeExceeded = labelMaxFileSizeExceeded },
    '$props.labelMaxFileSize' (labelMaxFileSize) { this.control.labelMaxFileSize = labelMaxFileSize },
    '$props.labelMaxTotalFileSizeExceeded' (labelMaxTotalFileSizeExceeded) { this.control.labelMaxTotalFileSizeExceeded = labelMaxTotalFileSizeExceeded },
    '$props.labelMaxTotalFileSize' (labelMaxTotalFileSize) { this.control.labelMaxTotalFileSize = labelMaxTotalFileSize }
  }
}
</script>

<style lang="scss">
@import '~filepond/dist/filepond.min.css';
@import '~filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';

$control-files-drop-bg: $gray-200 !default;

$control-files-drop-label-min-height: 50px !default;
$control-files-drop-label-font-size: $font-size-sm !default;
$control-files-drop-label-font-weight: $font-weight-base !default;
$control-files-drop-label-line-height: inherit !default;

.control-files {
  .form-control {
    padding: 0;
    height: auto;
    background-color: $control-files-drop-bg;
  }

  .filepond--root {
    margin: 0;

    .filepond--drop-label {
      min-height: $control-files-drop-label-min-height;

      label {
        font-size: $control-files-drop-label-font-size;
        font-weight: $control-files-drop-label-font-weight;
        line-height: $control-files-drop-label-line-height;
      }
    }

    .filepond--list-scroller {
      .filepond--list {
        .filepond--item {
          .filepond--file-wrapper {
            .filepond--file {
              .filepond--file-status {
                max-width: 80%;

                .filepond--file-status-main {
                  font-size: 11px;
                }

                .filepond--file-status-sub {
                  display: block;
                  margin-top: 0.1em;
                  font-size: 10px;
                  line-height: 1;
                  white-space: normal;
                }
              }
            }
          }
        }
      }
    }

    .filepond--panel {
      .filepond--panel-root {
        background-color: transparent;
      }
    }
  }

  &.is-disabled {
    .form-control {
      background-color: transparent;
      border: 0 none;

      .filepond--root {
        .filepond--drop-label {
          display: none;
        }

        .filepond--list-scroller {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
